import React, { useState, useEffect } from 'react';
import { TextField, Button, DialogActions, Select, MenuItem, FormControl, InputLabel, Box, Typography, CircularProgress } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const ServiceCardForm = ({ card, machines, onSubmit, onClose }) => {
    const [formData, setFormData] = useState({
        machine_id: '',
        action: '',
        description: '',
        date_of_service: '',
        next_service: '',
        spareParts: []
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        console.log('ServiceCardForm mounted or updated');
        if (card) {
            console.log('Initializing form with card data:', card);
            setFormData({
                ...card,
                date_of_service: card.date_of_service ? new Date(card.date_of_service).toISOString().split('T')[0] : '',
                next_service: card.next_service ? new Date(card.next_service).toISOString().split('T')[0] : '',
                spareParts: card.spareParts || []
            });
        } else {
            console.log('Initializing empty form');
            setFormData({
                machine_id: '',
                action: '',
                description: '',
                date_of_service: '',
                next_service: '',
                spareParts: []
            });
        }
    }, [card]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(`Field ${name} changed to:`, value);
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleAddSparePart = () => {
        console.log('Adding new spare part');
        setFormData({
            ...formData,
            spareParts: [...formData.spareParts, { id: uuidv4(), name: '', partNumber: '' }]
        });
    };

    const handleSparePartChange = (i, field, value) => {
        console.log(`Updating spare part ${i}, field ${field} to:`, value);
        const updatedSpareParts = [...formData.spareParts];
        updatedSpareParts[i][field] = value;
        setFormData({ ...formData, spareParts: updatedSpareParts });
        setErrors({ ...errors, spareParts: '' });
    };

    const handleRemoveSparePart = (i) => {
        console.log(`Removing spare part at index ${i}`);
        const updatedSpareParts = [...formData.spareParts];
        updatedSpareParts.splice(i, 1);
        setFormData({ ...formData, spareParts: updatedSpareParts });
    };

    const validateForm = () => {
        console.log('Validating form...');
        const newErrors = {};
        if (!formData.machine_id) newErrors.machine_id = 'Wybierz maszynę';
        if (!formData.action) newErrors.action = 'Podaj akcję serwisową';
        if (!formData.date_of_service) newErrors.date_of_service = 'Wybierz datę serwisu';

        const serviceDate = new Date(formData.date_of_service);
        const nextServiceDate = new Date(formData.next_service);
        if (formData.next_service && nextServiceDate <= serviceDate) {
            newErrors.next_service = 'Data następnego serwisu musi być późniejsza niż data serwisu';
        }

        const invalidParts = formData.spareParts.filter(part => !part.name || !part.partNumber);
        if (invalidParts.length > 0) {
            newErrors.spareParts = 'Wszystkie pola części zamiennych muszą być wypełnione';
        }

        setErrors(newErrors);
        console.log('Validation errors:', newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Submit button clicked');
        if (validateForm()) {
            console.log('Form validated successfully, attempting to submit');
            setIsSubmitting(true);
            try {
                console.log('Calling onSubmit with formData:', formData);
                await onSubmit({
                    ...formData,
                    spareParts: formData.spareParts.filter((part) => part.name && part.partNumber)
                });
                console.log('onSubmit completed successfully');
                onClose();
            } catch (error) {
                console.error('Error submitting form:', error);
                setErrors({ ...errors, submit: `Wystąpił błąd podczas zapisywania: ${error.message}` });
            } finally {
                setIsSubmitting(false);
            }
        } else {
            console.log('Form validation failed');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal" error={!!errors.machine_id}>
                <InputLabel id="machine-select-label">Maszyna</InputLabel>
                <Select
                    labelId="machine-select-label"
                    id="machine-select"
                    name="machine_id"
                    value={formData.machine_id}
                    onChange={handleChange}
                    label="Maszyna"
                >
                    {machines && machines.length > 0 ? (
                        machines.map((machine) => (
                            <MenuItem key={machine.id} value={machine.id}>
                                {`${machine.producer} - ${machine.type} (${machine.yearOfProduction})`}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem value="">Brak dostępnych maszyn</MenuItem>
                    )}
                </Select>
                {errors.machine_id && <Typography color="error">{errors.machine_id}</Typography>}
            </FormControl>
            <TextField
                fullWidth
                margin="normal"
                name="action"
                label="Akcja"
                value={formData.action}
                onChange={handleChange}
                error={!!errors.action}
                helperText={errors.action}
            />
            <TextField
                fullWidth
                margin="normal"
                name="description"
                label="Opis (opcjonalnie)"
                multiline
                rows={4}
                value={formData.description}
                onChange={handleChange}
            />
            <TextField
                fullWidth
                margin="normal"
                name="date_of_service"
                label="Data serwisu"
                type="date"
                value={formData.date_of_service}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={!!errors.date_of_service}
                helperText={errors.date_of_service}
            />
            <TextField
                fullWidth
                margin="normal"
                name="next_service"
                label="Następny serwis"
                type="date"
                value={formData.next_service}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={!!errors.next_service}
                helperText={errors.next_service}
            />
            <Box>
                <Typography variant="h6">Części zamienne</Typography>
                {formData.spareParts.map((part, i) => (
                    <Box key={part.id} display="flex" alignItems="center" mb={2}>
                        <TextField
                            name={`spareParts[${i}].name`}
                            label="Nazwa części"
                            value={part.name}
                            onChange={(e) => handleSparePartChange(i, 'name', e.target.value)}
                            sx={{ marginRight: 2 }}
                            error={!!errors.spareParts}
                        />
                        <TextField
                            name={`spareParts[${i}].partNumber`}
                            label="Numer katalogowy"
                            value={part.partNumber}
                            onChange={(e) => handleSparePartChange(i, 'partNumber', e.target.value)}
                            sx={{ marginRight: 2 }}
                            error={!!errors.spareParts}
                        />
                        <Button onClick={() => handleRemoveSparePart(i)}>Usuń</Button>
                    </Box>
                ))}
                {errors.spareParts && <Typography color="error">{errors.spareParts}</Typography>}
                <Button onClick={handleAddSparePart}>Dodaj część</Button>
            </Box>
            <DialogActions>
                <Button onClick={onClose} disabled={isSubmitting}>Anuluj</Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                    {isSubmitting ? <CircularProgress size={24} /> : (card ? 'Zapisz zmiany' : 'Dodaj')}
                </Button>
            </DialogActions>
            {errors.submit && <Typography color="error">{errors.submit}</Typography>}
        </form>
    );
};

export default ServiceCardForm;
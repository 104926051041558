import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, TextField, Typography, Box } from '@mui/material';
import { completeLogin } from '../services/apiService';
import { AuthContext } from '../context/AuthContext';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { login } = useContext(AuthContext); // Pobieramy funkcję login z kontekstu

  const handleLogin = async () => {
    setErrorMessage('');
    try {
      const response = await completeLogin({ username, password });
      if (response.data.accessToken && response.data.refreshToken) {
        // Używamy funkcji login z kontekstu do ustawienia tokenów i stanu uwierzytelnienia
        login(response.data.accessToken, response.data.refreshToken);
        navigate('/'); // Przekierowanie na dashboard
      } else {
        setErrorMessage('Nieprawidłowy login lub hasło.');
      }
    } catch (error) {
      setErrorMessage('Błąd podczas logowania.');
      console.error('Error during login process', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: '20px',
        backgroundColor: '#f0f2f5', // Dodanie tła dla lepszego wyglądu
      }}
    >
      <Typography variant="h3" gutterBottom>
        AgriPlanner
      </Typography>
      <Box
        sx={{
          width: '100%',
          maxWidth: '400px',
          textAlign: 'center',
          backgroundColor: 'white',
          padding: '30px',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h5" gutterBottom>
          Logowanie
        </Typography>
        <TextField
          fullWidth
          label="Nazwa użytkownika"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyPress={handleKeyPress}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Hasło"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress}
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          sx={{ marginTop: '16px' }}
        >
          Zaloguj się
        </Button>
        {errorMessage && (
          <Typography color="error" sx={{ marginTop: '16px' }}>
            {errorMessage}
          </Typography>
        )}
        <Box sx={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between' }}>
          <Link to="/reset-password" style={{ textDecoration: 'none', color: '#1976d2' }}>
            Przypomnienie hasła
          </Link>
          <Link to="/register" style={{ textDecoration: 'none', color: '#1976d2' }}>
            Rejestracja
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
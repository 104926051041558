import React, { useMemo } from 'react';
import { List, ListItem, ListItemText, Typography, Button, Box, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const ServiceCardsList = ({ serviceCards, machines, onEdit, onAdd }) => {
    const getMachineName = (machineId) => {
        const machine = machines.find(m => m.id === machineId);
        return machine ? `${machine.producer} - ${machine.type} (${machine.yearOfProduction})` : 'Nieznana maszyna';
    };

    const getMachineVIN = (machineId) => {
        const machine = machines.find(m => m.id === machineId);
        return machine ? `${machine.VIN}` : 'Brak VIN';
    };

    const sortedServiceCards = useMemo(() => {
        return [...serviceCards].sort((a, b) => {
            const dateA = new Date(a.next_service);
            const dateB = new Date(b.next_service);
            return dateA - dateB;
        });
    }, [serviceCards]);

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('pl-PL', options);
    };

    const getDaysUntilNextService = (nextServiceDate) => {
        const today = new Date();
        const nextService = new Date(nextServiceDate);
        const differenceInTime = nextService.getTime() - today.getTime();
        return Math.ceil(differenceInTime / (1000 * 3600 * 24));
    };

    const getBadgeColor = (daysUntil) => {
        if (daysUntil <= 7) return 'error';
        if (daysUntil <= 30) return 'warning';
        return 'success';
    };

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">Lista kart serwisowych</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={onAdd}
                >
                    Dodaj nową kartę
                </Button>
            </Box>

            {(!sortedServiceCards || sortedServiceCards.length === 0) ? (
                <Typography>Brak kart serwisowych do wyświetlenia.</Typography>
            ) : (
                <List>
                    {sortedServiceCards.map(card => {
                        const daysUntilNextService = getDaysUntilNextService(card.next_service);
                        const badgeColor = getBadgeColor(daysUntilNextService);

                        return (
                            <ListItem key={card.id}>
                                <ListItemText
                                    primary={
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="subtitle1" style={{ marginRight: '10px' }}>
                                                {`Akcja: ${card.action} `}
                                            </Typography>
                                            <Chip
                                                label={`Dni do serwisu: ${daysUntilNextService}`}
                                                color={badgeColor}
                                                size="small"
                                            />
                                        </Box>
                                    }
                                    secondary={
                                        <>
                                            <Typography component="span" variant="body2" color="text.primary">
                                                Maszyna: {getMachineName(card.machine_id)}
                                            </Typography>
                                            <br />
                                            <Typography component="span" variant="body2" color="text.primary">
                                                VIN: {getMachineVIN(card.machine_id)}
                                            </Typography>
                                            <br />
                                            <Typography component="span" variant="body2">
                                                Opis: {card.description || 'Brak opisu'}
                                            </Typography>
                                            <br />
                                            Data serwisu: {formatDate(card.date_of_service)}
                                            <br />
                                            <Typography component="span" variant="body2" color="text.primary">
                                                Następny serwis: {formatDate(card.next_service)}
                                            </Typography>
                                        </>
                                    }
                                />
                                <Button onClick={() => onEdit(card)}>Edytuj</Button>
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </Box>
    );
};

export default ServiceCardsList;